import React, { useState } from "react";
import { toast } from "react-toastify";
import logoutIcon from "../assets/icons/logout.png";
import { useNavigate } from "react-router-dom";
import { uploadImageApi } from "../api/ApiService";
import image from "../assets/icons/meshilogoback.png";

const UploadImage = ({ setMenu, setIsLoading }) => {
  const navigate = useNavigate();
  const [selectedModel, setSelectedModel] = useState("gpt-4o-2024-08-06");
  const [errors, setErrors] = useState({});
  const [file, setFile] = useState(null);

  const logoutClicked = () => {
    localStorage.removeItem("token");
    navigate("/login");
  }
  const handleFileChange = (e) => {
    setErrors({ ...errors, file: "" });
    setFile(e.target.files[0]);
  };
  const handleModelChange = (e) => {
    setSelectedModel(e.target.value);
  };

  const uploadImage = async (e) => {
    if (!file) {
      setErrors({
        file: "Please select a file first",
      });
      return;
    }

    setIsLoading(true);

    const formData = new FormData();
    formData.append("file", file);
    formData.append("modelName", selectedModel);

    uploadImageApi(formData)
      .then((response) => {
        setErrors({ ...errors, file: "" });
        setMenu(response.data?.data?.menu ?? []);
        if (response.status !== 200) {
          toast.error(response.data?.message ?? "Failed to extract menu data");
        }
      })
      .catch((err) => {
        toast.error(
          err.response?.data?.message ?? "Failed to extract menu data"
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  return (
    <div className="w-full max-w-4xl mx-auto px-8 py-12 rounded shadow-lg relative bg-white">
      <img
        className="w-10 h-10 absolute top right-8 hover:cursor-pointer"
        src={logoutIcon}
        onClick={() => logoutClicked()}
        alt="logout icon"
      />
      <img src={image} alt="" className="w-20 h-20" />
      <div className="px-8 py-8 text-center">
        <div className="font-bold text-3xl mb-2 text-gray-800 upload-title">Upload Image for Menu extraction
        </div>
        <p className="text-gray-600 text-lg mb-5">
          {/* Extract restaurant menu from an image */}
        </p>
      </div>
      <form className="max-w-lg mx-auto flex flex-col items-center">
        <div className="w-full mb-8">
          <label
            htmlFor="ModelName"
            className="block mb-5 text-xl font-semibold text-gray-800"
          >
            Select a model
          </label>
          <select
            id="ModelName"
            value={selectedModel}
            onChange={handleModelChange}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3"
          >
            <option value="gpt-4o-2024-08-06">gpt-4o-2024-08-06</option>
            <option value="gpt-4o">gpt-4o</option>
            <option value="gpt-4o-mini">gpt-4o-mini</option>
            <option value="gpt-4-vision-preview">gpt-4-vision-preview</option>
          </select>
        </div>
        <div className="w-full mb-8">
          <label
            className="block mb-5 text-xl font-semibold text-gray-800"
            htmlFor="fileInput"
          >
            Upload Menu Image
          </label>
          <input
            onChange={handleFileChange}
            className={`block w-full text-base  border  rounded-lg cursor-pointer ${errors.file
                ? "bg-red-50 border-red-500 text-red-900"
                : "bg-gray-50 border-gray-300 text-gray-900"
              } focus:outline-none`}
            id="fileInput"
            type="file"
          />
          {errors.file && (
            <p className="mt-2 text-sm text-red-600 dark:text-red-500">
              <span className="font-medium">{errors.file}</span>
            </p>
          )}
        </div>
        <button
          type="button"
          onClick={() => uploadImage()}
          className="relative w-75 my-9 z-30 inline-flex items-center justify-center w-auto px-8 py-3 overflow-hidden font-bold text-white transition-all duration-500 border border-gray-200 rounded-md cursor-pointer group ease hover:!bg-red-700 active:!bg-red-800"
          style={{ backgroundColor: '#CF1B10' }}
        >
          Upload Image and Extract Data
        </button>
      </form>
      <div className="text-center mt-8">
        <a
          href="https://youtu.be/KQ0XImjkw5Y"
          className="text-blue-500 underline"
        >
          Example video: From menu to website in a few minutes
        </a>
      </div>
    </div>
  );
};

export default UploadImage;