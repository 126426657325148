import React from "react";
import PropTypes from "prop-types";
import CategoryItem from "./CategoryItem";
import axios from "axios";
import { toast } from "react-toastify";
import { importDataIntoDB } from "../api/ApiService";

const apiUrl = process.env.REACT_APP_API_URL + "/import";

const ProductViewer = ({ setMenu, menuItems, setIsLoading }) => {
  const distributeProducts = (menuItems) => {
    const leftItems = [];
    const rightItems = [];
    let leftProductCount = 0;
    let rightProductCount = 0;

    menuItems.forEach((category) => {
      const productCount = category.products?.length ?? 0;

      if (leftProductCount <= rightProductCount) {
        leftItems.push(category);
        leftProductCount += productCount;
      } else {
        rightItems.push(category);
        rightProductCount += productCount;
      }
    });

    return { leftItems, rightItems };
  };
  const { leftItems, rightItems } = distributeProducts(menuItems);
  const continueImport = async () => {
    setIsLoading(true);
    importDataIntoDB(menuItems)
      .then((response) => {
        if (response.status === 200) {
          toast.success(response.data.message);
          setMenu([]);
        } else {
          toast.error(response.data?.message ?? "Failed to import menu");
        }
      })
      .catch((err) => {
        toast.error(err.response?.data?.message ?? "Failed to import menu");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  return (
    <div className="h-screen w-screen  flex justify-between bg-slate-50 flex-col relative">
      <div className="w-full h-[90%] overflow-y-auto">
        <div className="md:hidden w-full">
          {menuItems?.map((category, index) => (
            <CategoryItem category={category} key={index} />
          ))}
        </div>
        <div className="hidden md:block w-full">
          <div className="flex flex-row justify-between items-start">
            <div className="w-[50%]">
              {leftItems?.map((category, index) => (
                <CategoryItem category={category} key={index} />
              ))}
            </div>
            <div className="w-[50%]">
              {rightItems?.map((category, index) => (
                <CategoryItem category={category} key={index} />
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-around md:flex-row flex-col items-center">
        <button
          type="button"
          onClick={() => setMenu([])}
          className="relative md:my-9 my-2 z-30 inline-flex items-center justify-center w-auto px-10 py-3 overflow-hidden font-bold text-gray-500 transition-all duration-500 border border-gray-200 rounded-md cursor-pointer group ease bg-gradient-to-b from-white to-gray-50 hover:from-gray-50 hover:to-white active:to-white"
        >
          <span className="w-full h-0.5 absolute bottom-0 group-active:bg-transparent left-0 bg-gray-100"></span>
          <span className="h-full w-0.5 absolute bottom-0 group-active:bg-transparent right-0 bg-gray-100"></span>
          {/*
           */}{" "}
          Try Another Image with Menu information
        </button>
        <button
          type="button"
          onClick={() => continueImport()}
          className="relative px-10 h-[50px] py-3 font-medium text-white transition duration-300 bg-green-400 rounded-md hover:bg-green-500 ease"
        >
          <span className="absolute bottom-0 left-0 h-full -ml-2">
            <svg
              viewBox="0 0 487 487"
              className="w-auto h-full opacity-60 object-stretch"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 .3c67 2.1 134.1 4.3 186.3 37 52.2 32.7 89.6 95.8 112.8 150.6 23.2 54.8 32.3 101.4 61.2 149.9 28.9 48.4 77.7 98.8 126.4 149.2H0V.3z"
                fill="#FFF"
                fillRule="nonzero"
                fillOpacity=".1"
              />
            </svg>
          </span>
          <span className="absolute top-0 right-0 w-12 h-full -mr-3">
            <svg
              viewBox="0 0 487 487"
              className="object-cover opacity-60 w-full h-full"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M487 486.7c-66.1-3.6-132.3-7.3-186.3-37s-95.9-85.3-126.2-137.2c-30.4-51.8-49.3-99.9-76.5-151.4C70.9 109.6 35.6 54.8.3 0H487v486.7z"
                fill="#FFF"
                fillRule="nonzero"
                fillOpacity=".1"
              />
            </svg>
          </span>
          <span className="relative">Continue Import into Meshi database</span>
        </button>
      </div>
    </div>
  );
};

ProductViewer.propTypes = {
  setIsLoading: PropTypes.func.isRequired,
  setMenu: PropTypes.func.isRequired,
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      category: PropTypes.string.isRequired,
      products: PropTypes.arrayOf(
        PropTypes.shape({
          name:
            PropTypes.shape({
              en: PropTypes.string.isRequired,
              nl: PropTypes.string.isRequired,
            }).isRequired,
          price: PropTypes.string.isRequired,
          description: 
          // PropTypes.string.isRequired,
          PropTypes.shape({
            en: PropTypes.string.isRequired,
            nl: PropTypes.string.isRequired,
          }).isRequired,
        }).isRequired,
      )
    }).isRequired,
  )
};
export default ProductViewer;
