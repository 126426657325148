import React, { useState } from "react";
import UploadImage from "./UploadImageAndExtractData";
import LoadingView from "./LoadingView";
import ProductViewer from "./ProductViewer";

const MenuExtractor = () => {
  const [menuItems, setMenu] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  // console.log(menuItems);
  return !isLoading ? (
    <div className="container mx-auto flex items-center justify-center h-screen w-screen ">
      {menuItems?.length > 0 ? (
        <ProductViewer
          setMenu={setMenu}
          menuItems={menuItems}
          setIsLoading={setIsLoading}
        />
      ) : (
        <UploadImage setMenu={setMenu} setIsLoading={setIsLoading} />
      )}
    </div>
  ) : (
    <LoadingView />
  );
};

export default MenuExtractor;
