import React from "react";
import MenuExtractor from "../components/MenuExtractor";

const ImportPage = () => {
  return (
    <>
      <MenuExtractor />
    </>
  );
};

export default ImportPage;
