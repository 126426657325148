import React from "react";
import PropTypes from "prop-types";
import ProductItem from "./ProductItem";

const CategoryItem = ({ category }) => {
  return (
    <div className={`border-2 m-5 border-stone-500 mt-8`}>
      <div>
        <h2 className="font-bold text-orange-900 text-2xl my-2 text-center">
          {category.category}
        </h2>
      </div>
      <div className="mb-2">
        {category?.products?.map((product, index) => {
          return <ProductItem product={product} key={index} />;
        })}
      </div>
    </div>
  );
};
CategoryItem.propTypes = {
  category: PropTypes.shape({
    category: PropTypes.string.isRequired,
    products: PropTypes.arrayOf(
      PropTypes.shape({
        name: 
        // PropTypes.arrayOf(
        //     PropTypes.string.isRequired,
        // ).isRequired,
        PropTypes.shape({
          en: PropTypes.string.isRequired,
          nl: PropTypes.string.isRequired,
        }).isRequired,
        price: PropTypes.string.isRequired,
        description: 
        // PropTypes.string.isRequired,
        PropTypes.shape({
          en: PropTypes.string.isRequired,
          nl: PropTypes.string.isRequired,
        }).isRequired,
      }).isRequired,
    ).isRequired,
  }).isRequired,
};
export default CategoryItem;
