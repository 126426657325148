import React, { Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import LoadingView from "../components/LoadingView";
import ImportPage from "../pages/ImportPage";
import ProtectedRoute from "./ProtectedRoute";

const PrivateRoutes = () => {
  return (
    <Suspense fallback={<LoadingView />}>
      <Routes>
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <ImportPage />
            </ProtectedRoute>
          }
        />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Suspense>
  );
};

export default PrivateRoutes;
