import { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import LoadingView from "../components/LoadingView";
import LoginPage from "../pages/LoginPage";
import PrivateRoutes from "./PrivateRoutes";

const Switchs = () => {
  return (
    <Suspense fallback={<LoadingView />}>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="*" element={<PrivateRoutes />} />
      </Routes>
    </Suspense>
  );
};
export { Switchs };
