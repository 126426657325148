import React from "react";
import PropTypes from "prop-types";

const ProductItem = ({ product }) => {
  // console.log(product);
  return (
    <div className="py-2 px-5">
      <div className="flex flex-row justify-between">
        <div>
          <p className="font-bold text-gray-700 text-xl">{product.name}</p>
          <p className="mt-1 text-gray-600 text-md">{product.description}</p>
        </div>
        <div>
          <p className="font-bold text-gray-700 text-xl">{product.price}</p>
        </div>
      </div>
    </div>
  );
};
ProductItem.propTypes = {
  product: PropTypes.shape({
    // id: PropTypes.number.isRequired,
    name:
      PropTypes.shape({
        en: PropTypes.string.isRequired,
        nl: PropTypes.string.isRequired,
      }).isRequired,
    price: PropTypes.string.isRequired,
    description: 
    // PropTypes.string.isRequired,
    PropTypes.shape({
      en: PropTypes.string.isRequired,
      nl: PropTypes.string.isRequired,
    }).isRequired,
    prices: PropTypes.arrayOf(
      PropTypes.shape({
        variant: PropTypes.string.isOptional,
        price: PropTypes.string.isRequired,
      })
    ).isRequired,
    description: 
    // PropTypes.string.isRequired,
    PropTypes.shape({
      en: PropTypes.string.isRequired,
      nl: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};
export default ProductItem;
